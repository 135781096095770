.card {
    background: transparent;
    color: $body-color;
    border-color: rgba(255, 255, 255, .1);
    border-radius: 0;
    border-width: 2px;

    .card-header {
        color: #fff;
        border-color: rgba(255, 255, 255, .1);
        border-radius: 0;
        border-width: 0 0 2px;
    }

    .list-group {
        .list-group-item {
            background: transparent;
            color: $body-color;
            border-color: rgba(255, 255, 255, .1);

            &:not(:last-child) {
                border-width: 0 0 2px;
            }
        }
    }
}
